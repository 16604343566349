import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const sliderElements = document.querySelectorAll(".js-carousel");

// Check if any Sliders appear on page.
if (sliderElements.length) {
  // For each of them, generate a keen slider.
  sliderElements.forEach((sliderElement) => {
    let interval = 0;
    function autoplay(run) {
      clearInterval(interval);
      interval = setInterval(() => {
        if (run && slider) {
          slider.next();
        }
      }, 5000);
    }

    const slider = new KeenSlider(sliderElement, {
      loop: true,
      slidesPerView: 1,

      created: function (instance) {
        const slides = sliderElement.querySelectorAll(".js-carousel__slide");
        const dots_wrapper = document.querySelector(".js-carousel__dots");

        sliderElement
          .querySelector(".js-carousel__arrow--left")
          .addEventListener("click", function () {
            instance.prev();
          });

        sliderElement
          .querySelector(".js-carousel__arrow--right")
          .addEventListener("click", function () {
            instance.next();
          });

        slides.forEach(function (t, idx) {
          const dot = document.createElement("button");
          dot.classList.add("carousel__dot");
          dots_wrapper.appendChild(dot);
          dot.addEventListener("click", function () {
            instance.moveToSlide(idx);
          });
        });

        updateClasses(instance);
      },

      slideChanged(instance) {
        updateClasses(instance);
      },
    });

    sliderElement.addEventListener("mouseover", () => {
      autoplay(false);
    });
    sliderElement.addEventListener("mouseout", () => {
      autoplay(true);
    });

    autoplay(true);
  });
}

function updateClasses(instance) {
  const slide = instance.details().relativeSlide;
  const dots = document.querySelectorAll(".carousel__dot");

  dots.forEach(function (dot, idx) {
    idx === slide
      ? dot.classList.add("carousel__dot--active")
      : dot.classList.remove("carousel__dot--active");
  });
}
