const horizontalTabs = document.querySelectorAll(".tabs--horizontal");
const horizontalTabsMenu = document.querySelector(".js-tabs__menu--horizontal");
const horizontalTabsContent = document.querySelectorAll(
  ".js-tabs__content--horizontal"
);
const horizontalTabsButton = document.querySelectorAll(
  ".js-tabs__button--horizontal"
);
const hIndicator = document.querySelector(".js-tabs__indicator--horizontal");

const verticalTabs = document.querySelectorAll(".tabs--vertical");
const verticalTabsMenu = document.querySelector(".js-tabs__menu--vertical");
const verticalTabsContent = document.querySelectorAll(
  ".js-tabs__content--vertical"
);
const verticalTabsButton = document.querySelectorAll(
  ".js-tabs__button--vertical"
);
const vIndicator = document.querySelector(".js-tabs__indicator--vertical");

const CSS = {
  active: "active",
  block: "block",
  current: " current",
  focus: "focus",
  hidden: "none",
};

//To be executed on every tab change
const handleTabSelection = (e) => {
  moveIndicator(e);
  toggleActiveClass(e);
};

//Handle openTab
const openTab = (e, tabName) => {
  if (e.target.className.includes("--vertical")) {
    verticalTabsContent.forEach((tab) => {
      tab.style.display = CSS.hidden;
    });

    verticalTabsButton.forEach((link) => {
      link.className = link.className.replace(CSS.current, "");
    });
    document.getElementById(tabName).style.display = CSS.block;
    e.currentTarget.className += CSS.current;
  } else {
    horizontalTabsContent.forEach((tab) => {
      tab.style.display = CSS.hidden;
    });

    horizontalTabsButton.forEach((link) => {
      link.className = link.className.replace(CSS.current, "");
    });
    document.getElementById(tabName).style.display = CSS.block;
    e.currentTarget.className += CSS.current;
  }
};

const toggleActiveClass = (e) => {
  const current = document.querySelector(`.${CSS.active}`);
  if (current) {
    current.className = current.className.replace(` ${CSS.active}`, "");
  }

  e.target.className += ` ${CSS.active}`;
};

//Initially activate the first tab content
horizontalTabsContent.forEach((tab, idx) => {
  if (idx === 0) {
    tab.style.display = CSS.block;
  }
});

//Initially activate the first tab content
verticalTabsContent.forEach((tab, idx) => {
  if (idx === 0) {
    tab.style.display = CSS.block;
  }
});

if (horizontalTabs.length > 0) {
  horizontalTabsButton.forEach((tabItem, idx) => {
    tabItem.addEventListener("click", (e) => {
      openTab(e, e.target.innerText.toLowerCase());
    });

    tabItem.addEventListener("click", (e) => {
      handleTabSelection(e);

      const current = document.querySelector(`.${CSS.focus}`);
      if (current) {
        current.className = current.className.replace(` ${CSS.focus}`, "");
      }
    });

    if (idx === 0) {
      tabItem.className += CSS.current;
    }
  });
}

if (verticalTabs.length > 0) {
  verticalTabsButton.forEach((tabItem, idx) => {
    tabItem.addEventListener("click", (e) => {
      openTab(e, e.target.innerText.toLowerCase());
    });

    tabItem.addEventListener("click", (e) => {
      handleTabSelection(e);

      const current = document.querySelector(`.${CSS.focus}`);
      if (current) {
        current.className = current.className.replace(` ${CSS.focus}`, "");
      }
    });

    if (idx === 0) {
      tabItem.className += CSS.current;
    }
  });
}

if (horizontalTabs.length > 0) {
  hIndicatorPosition =
    horizontalTabsButton[0].getBoundingClientRect().left -
    horizontalTabsMenu.getBoundingClientRect().left;
  hIndicator.style.width = `${horizontalTabsButton[0].clientWidth}px`;
}

if (verticalTabs.length > 0) {
  vIndicatorPosition =
    verticalTabsButton[0].getBoundingClientRect().top -
    verticalTabsMenu.getBoundingClientRect().top;
  vIndicator.style.height = `${verticalTabsButton[0].clientHeight}px`;
}

const moveIndicator = (e) => {
  if (e.target.className.includes("--vertical")) {
    const vIndicatorPosition =
      e.target.getBoundingClientRect().top -
      verticalTabsMenu.getBoundingClientRect().top;
    vIndicator.style.height = `${e.target.clientHeight}px`;
    vIndicator.style.top = `${vIndicatorPosition}px`;
  } else {
    const hIndicatorPosition =
      e.target.getBoundingClientRect().left -
      horizontalTabsMenu.getBoundingClientRect().left;
    hIndicator.style.width = `${e.target.clientWidth}px`;
    hIndicator.style.left = `${hIndicatorPosition}px`;
  }
};
