import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const sliderElements = document.querySelectorAll(".js-image-bar__slider");

if (sliderElements.length) {
  sliderElements.forEach((sliderElement) => {
    var interval = 0;
    function autoplay(run) {
      clearInterval(interval);
      interval = setInterval(() => {
        if (run && slider) {
          slider.next();
        }
      }, 2000);
    }

    var slider = new KeenSlider(sliderElement, {
      loop: true,
      duration: 1000,
      slidesPerView: 4,
      dragStart: () => {
        autoplay(false);
      },
      dragEnd: () => {
        autoplay(true);
      },
    });

    autoplay(true);

    sliderElement.addEventListener("mouseover", () => {
      autoplay(false);
    });
    sliderElement.addEventListener("mouseout", () => {
      autoplay(true);
    });
  });
}
