const accordion = document.querySelectorAll(".js-accordion");

if (accordion && accordion.length) {
  const CSS = {
    active: "active",
  };

  accordion.forEach((accordionInstance) => {
    const items = accordionInstance.querySelectorAll(".js-accordion__item");
    const select = accordionInstance.querySelector("#types");

    items.forEach((item) => {
      const title = item.querySelector(".js-accordion__item--title");
      1;
      title.addEventListener("click", () => {
        // Toggle current item
        toggle_item(item);
        title.classList.toggle(CSS.active);
      });
    });

    const toggle_item = (item) => {
      const body = item.querySelector(".js-accordion__item--body");

      if (item.classList.contains(CSS.active)) {
        body.removeAttribute("style");
        item.classList.remove(CSS.active);
      } else {
        body.style.height = body.scrollHeight + "px";
        item.classList.add(CSS.active);

        window.addEventListener("resize", () => {
          body.style.height = body.scrollHeight + "px";
        });
      }
    };

    select.addEventListener("change", (ev) => {
      let value = ev.target.value;

      items.forEach((item) => {
        let valueWitNoSpace = value.replace(/\s+/g, "");

        if (item.classList.contains(valueWitNoSpace) || value === "") {
          item.style.display = "block";
        } else {
          item.style.display = "none";
        }
      });
    });
  });
}
