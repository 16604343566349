import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";

const bannerSlider = document.querySelector(".js-banner__carousel");

if (bannerSlider && bannerSlider.childElementCount > 1) {
  let interval = 0;
  function autoplay(run) {
    clearInterval(interval);
    interval = setInterval(() => {
      if (run && slider) {
        slider.next();
      }
    }, 5000);
  }

  const slider = new KeenSlider(bannerSlider, {
    loop: true,
    duration: 1000,
    slidesPerView: 1,

    dragStart: () => {
      autoplay(false);
    },
    dragEnd: () => {
      autoplay(true);
    },
  });

  autoplay(true);

  bannerSlider.addEventListener("mouseover", () => {
    autoplay(false);
  });
  bannerSlider.addEventListener("mouseout", () => {
    autoplay(true);
  });
}
