const modalTriggers = document.querySelectorAll(".js-modal__trigger");
const overlay = document.querySelector(".js-modal");
const body = document.body;

if (modalTriggers.length) {
  const CSS = {
    visible: "visible",
    block: "block",
    open: "modal--open",
  };

  modalTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const { popupTrigger } = trigger.dataset;
      const popupModal = document.querySelector(
        `[data-modal="${popupTrigger}"]`
      );
      setModalCenter(`[data-modal="${popupTrigger}"]`);

      popupModal.classList.add(CSS.visible);
      overlay.classList.add(CSS.block);
      body.classList.add(CSS.open);

      popupModal
        .querySelector(".js-modal__close-btn")
        .addEventListener("click", () => {
          popupModal.classList.remove(CSS.visible);
          overlay.classList.remove(CSS.block);
          body.classList.remove(CSS.open);
        });

      overlay.addEventListener("click", () => {
        popupModal.classList.remove(CSS.visible);
        overlay.classList.remove(CSS.block);
        body.classList.remove(CSS.open);
      });

      window.addEventListener("resize", () => {
        setModalCenter(`[data-modal="${popupTrigger}"]`);
      });
    });
  });
}

const setModalCenter = (classORid) => {
  const div = document.querySelector(classORid);

  const height = div.offsetHeight;
  const innerHeight = window.innerHeight;

  div.style.top = (innerHeight - height) / 2 + window.pageYOffset + "px";
};
