window.setTimeout(() => {
  const head = document.getElementsByTagName("head")[0];

  const l1 = document.createElement("link");
  l1.async = true;
  l1.rel = "stylesheet";
  l1.type = "text/css";
  l1.href =
    "https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css";
  head.appendChild(l1);

  const s1 = document.createElement("script");
  s1.async = true;
  s1.src =
    "https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js";
  head.appendChild(s1);

  const s2 = document.createElement("script");
  s2.async = true;
  s2.src = "//instant.page/1.2.0";
  s2.type = "module";
  head.appendChild(s2);

  if (window.cookieconsent) {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#64386b",
          text: "#ffcdfd",
        },
        button: {
          background: "#f8a8ff",
          text: "#3f0045",
        },
      },
      theme: "classic",
      content: {
        message: "This site uses cookies in order to function correctly.",
        href: "/privacy-policy",
      },
    });
  } else {
    window.setTimeout(() => {
      window.cookieconsent.initialise({
        palette: {
          popup: {
            background: "#64386b",
            text: "#ffcdfd",
          },
          button: {
            background: "#f8a8ff",
            text: "#3f0045",
          },
        },
        theme: "classic",
        content: {
          message: "This site uses cookies in order to function correctly.",
          href: "/privacy-policy",
        },
      });
    }, 2000);
  }
}, 2000);
