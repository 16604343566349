//Get the button:
topBtn = document.querySelector(".js-top-btn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

const scrollFunction = () => {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    topBtn.style.display = "flex";
  } else {
    topBtn.style.display = "none";
  }
};

// When the user clicks on the button, scroll to the top of the document
const topFunction = () => {
  const offSetTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  if (offSetTop > 0) {
    window.requestAnimationFrame(topFunction);
    window.scrollTo(0, offSetTop - offSetTop / 16);
  }
};

topBtn.addEventListener("click", topFunction);
